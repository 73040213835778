import request from '@/utils/request'


//下拉选项
export function getOptions(query) {
	return request({
		url: '/appcarsourcehomepage/bottominput',
		method: 'get',
		params: query
	})
}

//下拉品牌
export function getBrandOption(query) {
	return request({
		url: '/appcarsourcehomepage/brandinput',
		method: 'get',
		params: query
	})
}

//下拉城市
export function getCityOption(query) {
	return request({
		url: '/appcarsourcehomepage/cityinput',
		method: 'get',
		params: query
	})
}

//下拉车系
export function getSerialOption(query) {
	return request({
		url: '/appcarsourcehomepage/selectserialbybrand',
		method: 'get',
		params: query
	})
}

//IP
export function getIp(query) {
	return request({
		url: '/appcarsourcehomepage/iptoaddress',
		method: 'get',
		params: query
	})
}

//车源列表
export function getCars(query) {
	return request({
		url: '/appcarsourcehomepage/carsourcelist',
		method: 'get',
		params: query
	})
}

//车源详情
export function getCarDetail(query) {
	return request({
		url: '/carsourcedetailpage/detailpage',
		method: 'get',
		params: query
	})
}

//车源详情-更多图片
export function getCarOtherPhoto(query) {
	return request({
		url: '/carsourcedetailpage/otherphoto',
		method: 'get',
		params: query
	})
}



