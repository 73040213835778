<template>
  <div class="business-page">
    <pageHeader></pageHeader>

    <div class="page-banner"></div>
    <!--<div class="page-menu">
      <div class="content-inner">
        <ul>
          <li><router-link to="/car/zhanshi" :class="activeName=='zhanshi'?'active':''">车源展示</router-link></li>
          <li><router-link to="/car/paimai"  :class="activeName=='paimai'?'active':''">拍卖车源</router-link></li>
          <li><router-link to="/car/xunjia" :class="activeName=='xunjia'?'active':''">询价车源</router-link></li>
          <li><router-link to="/car/yuzhanzhong" :class="activeName=='yuzhanzhong'?'active':''">预展中车源</router-link></li>
          <li><router-link to="/car/yichengjiao" :class="activeName=='yichengjiao'?'active':''">已成交车源</router-link></li>
        </ul>
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="clear"></div>
      </div>
    </div>-->

    <router-view />

    <pageFooter></pageFooter>







  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {getOptions, getBrandOption, getCityOption, getSerialOption, getCars} from "@/api/car";

export default {
  components: {pageHeader, pageFooter},
  data() {
    return {
      loading: true,
      activeName:'',
      title:'',
      options:null,
      cars:[],
    };
  },
  mounted() {
    this.activeName = this.$route.name
    this.title = this.$route.meta.title

    this.getOptions()
  },
  methods: {
    getOptions(){
      getOptions().then(response => {
        this.options=response.data
      })
      getBrandOption().then(response => {
        this.options=response.data
      })
      getCityOption().then(response => {
        this.options=response.data
      })
      getSerialOption().then(response => {
        this.options=response.data
      })
    },
    getCars(){
      getCars().then(response => {
        this.cars=response.data
      })
    },
  },
  watch:{
    $route(to){
      this.activeName = to.name
      this.title = to.meta.title
    }
  }
};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../../assets/images/banner-4.jpg");
}


</style>
